<template>
  <router-view />
</template>
<script>
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
</style>
