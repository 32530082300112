<template>
  <div class="home">
    <video
      controls="false"
      muted
      autoplay
      ref="video"
      id="video"
      poster="@/assets/img/default@2x.png"
      preload="auto"
      webkit-playsinline="true"
      playsinline="true"
      x-webkit-airplay="allow"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-orientation="portraint"
      style="object-fit:cover">
      <source src="@/assets/video/official.mp4" type="video/mp4">
      <source src="@/assets/video/official.ogg" type="video/ogg">
      <source src="@/assets/video/official.webm" type="video/webm">
      抱歉，您的浏览器不支持内嵌视频
    </video>
    <div class="top">
      <img src="@/assets/img/logo@2x.png" alt="" class="logo" @click="goNext(0)"/>
      <div class="van">
        <div class="first" @click="goNext(0)">
          <button class="navigation">首页</button>
          <div class="underline"></div>
        </div>
        <div class="first" @click="goNext(1)">
          <button class="navigation navigation_second">关于我们</button>
        </div>
      </div>
      <img src="@/assets/img/icon_un-mute@2x.png" alt="" class="play" v-if="isPlay" @click="pause()"/>
      <img src="@/assets/img/icon_mute@2x.png" alt="" class="play" v-else @click="play()"/>
    </div>
    <div class="download">
      <div class="buttons">
        <button class="button" @mouseenter="isIOS = true" @mouseleave="isIOS = false" @click="goIOS">
          <div class="content_ios">
            <img src="@/assets/img/icon_ios@2x.png" alt="" class="apple" />
            App Store
          </div>
          <div class="QR" v-show="isIOS" @click="goIOS">
            <img src="@/assets/img/qr_ios.png" alt="" class="qr" />
          </div>
        </button>

        <button class="button"  @mouseenter="isAndroid = true" @mouseleave="isAndroid = false" @click="goAndroid">
          <div class="content_andioid">
            <img
              src="@/assets/img/icon_android@2x.png"
              alt=""
              class="android"
            />
            Android
          </div>
          <div class="QR" v-show="isAndroid" @click="goAndroid">
            <img src="@/assets/img/qr_android.png" alt="" class="qr" />
          </div>
        </button>
      </div>
      <div class="text">哼～，你来与不来，我都在这里嗨—，就是这么feel爽</div>
    </div>
    <div class="page_bottom">
      copyright © 2020-2021 一点APP All Rights Reserved.京公网安备11010502043号
      <a href="http://beian.miit.gov.cn/" class="page_bottom_a">京ICP备2020047090号-2</a>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const isPlay = ref(false)
// const van = [{ name: '首页' }, { name: '关于我们' }]
const isIOS = ref(false)
const isAndroid = ref(false)
const goNext = (index) => {
  if (index === 0) {
    router.push({
      path: '/'
    })
  }
  if (index === 1) {
    router.push({
      path: '/address'
    })
  }
}
const video = ref(null)
const play = () => {
  video.value.muted = false
  isPlay.value = true
}
const pause = () => {
  video.value.muted = true
  isPlay.value = false
}
const goIOS = () => {
  window.location.href = 'https://apps.apple.com/cn/app/id1532682845'
}
const goAndroid = () => {
  window.location.href = 'https://sj.qq.com/appdetail/com.bittimes.yidian'
}
</script>
<style scoped>
video{
  width: 100%;
  height: auto;
  min-height: 100%;
  z-index: -1;
  object-fit: contain;
}
video::-webkit-media-controls {
  display: none !important;
}
.home {
  width: 100%;
  height: 100vh;
  background: radial-gradient(49.33% 61.39% at 50% 50%, rgba(6, 6, 9, 0.3) 26.04%, rgba(6, 6, 9, 0.9) 94.85%);
  /* background-image: url("@/assets/img/default@2x.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover; */
  position: relative;
  display: flex;
  justify-content: center;
}
.logo {
  width: 140px;
  height: 44px;
  position: absolute;
  top: 93px;
  left: 360px;
}
.van {
  position: absolute;
  width: 182px;
  min-width: 182px;
  height: 34px;
  min-height: 34px;
  top: 93px;
  right: 360px;
  display: flex;
  justify-content: space-between;
}
.first {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.89);
}
.navigation{
  border: none;
  background: #000;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.89);
}
.navigation_second{
  color: rgba(255, 255, 255, 0.6);
}
.underline {
  width: 24px;
  height: 4px;

  background: rgba(255, 255, 255, 0.89);
  border-radius: 20px;
}
.play {
  width: 20px;
  height: 23px;
  position: absolute;
  top: 91px;
  right: 285px;
}
.download {
  width: 712px;
  height: 208px;
  min-width: 500px;
  min-height: 146px;
  position: absolute;
  top: 77.4%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttons {
  width: 632px;
  height: 84px;
  display: flex;
  justify-content: space-between;
}
.button {
  width: 300px;
  height: 84px;
  font-weight: 700;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.89);
  line-height: 42px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.89);
  backdrop-filter: blur(4px);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.content_ios {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content_andioid {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.QR {
  width: 260px;
  height: 260px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: absolute;
  top: -302px;
}
.QR::after {
  content: "";
  position: absolute;
  left: 100px;
  top: 250px;
  border-radius: 4px;
  border-top: 42px solid #fff;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
}
.qr {
  width: 250px;
  height: 250px;
}
.apple {
  width: 37px;
  height: 40px;
}
.android {
  width: 36px;
  height: 42px;
}
.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 16px;
}
.page_bottom {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  bottom: 14px;
}
.page_bottom_a{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}
</style>
