<template>
<div class="all">
  <div class="top">
    <img src="@/assets/img/logo@2x.png" alt="" class="web_logo" @click="goNext(0)"/>
    <div class="van">
      <div class="first" style="color: rgba(255, 255, 255, 0.6);" @click="goNext(0)">
        <button class="navigation">首页</button>
      </div>
      <div class="first" @click="goNext(1)">
      <button class="navigation navigation_second">关于我们</button>
        <div class="underline"></div>
      </div>
    </div>
  </div>
  <div class="address">
    <div class="title">关于一点</div>
    <p>“一点” “一点” APP是北京比特次元科技有限公司开发的一款基于个人非隐私交互数据的综合社交平台或者年轻人在线社交平台），APP主要运用了智能二阶信息数据系统和“一人多面”的算法机制，实现了既能最大化的保证用户的隐私，又可以帮用户推荐更多更匹配的感兴趣内容。</p>
    <br/>
    <p>“一点”融入了“圈子、合拍、匹配、语音交友”等多种玩法，这些玩法都可以帮助用户更快更好的匹配到更加兴趣契合的朋友，使用户的交友体验变得更加的简单有趣。</p>
    <br/>
    <p>“一点”APP的初衷在于：一切都是以真实的数据为基础，催生社区、社群及社交的一体化发展，将人与人的链接维度由传统的“人到人”好友拓展模式，更新且深化至信息单元维度，忠实地还原社会生活本身，在信息数据的基础上重构关系链，并尝试解决用户社会化生存中不断产生的焦虑孤独、信息逼仄、信息冗余、关系链侵蚀/固化等传统算法机制下的弊端，是一个“有温度”且“懂你”的产品。</p>
    <div class="title_map">联系我们</div>
    <div class="map">
      <img src="@/assets/img/map_web@2x.png" alt="" class="map_pic">
      <div class="map_text">
        <div class="text_item">
          <img src="@/assets/img/icon_name_white@2x.png" alt="" class="icon">
          <div class="text">
            <div class="text_title">公司名称</div>
            <div>北京比特次元科技有限公司</div>
          </div>
        </div>
        <div class="text_item">
          <img src="@/assets/img/icon_address_white@2x.png" alt="" class="icon">
          <div class="text">
            <div class="text_title">地址</div>
            <div style="line-height: 2vw;">北京市朝阳区</div>
            <div>西大望路1号温特莱中心B座601室</div>
          </div>
        </div>
        <div class="text_item">
          <img src="@/assets/img/icon_mailbox_white@2x.png" alt="" class="icon">
          <div class="text">
            <div class="text_title">邮箱</div>
            <div>xiaobin@yidian.net.cn</div>
          </div>
        </div>
        <div class="text_item" style="margin: 0;">
          <img src="@/assets/img/icon_receive_white@2x.png" alt="" class="icon">
          <div class="text">
            <div class="text_title">公关专访接待</div>
            <div>company@yidian.net.cn</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page_bottom">
      copyright © 2020-2021 一点APP All Rights Reserved.京公网安备11010502043号
      <a href="http://beian.miit.gov.cn/" class="page_bottom_a">京ICP备2020047090号-2</a>
    </div>
</div>
</template>
<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()
// const van = [{ name: '首页' }, { name: '关于我们' }]
const goNext = (index) => {
  if (index === 0) {
    router.push({
      path: '/'
    })
  }
  if (index === 1) {
    router.push({
      path: '/address'
    })
  }
}
</script>
<style>
.all{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.web_logo {
  width: 140px;
  height: 44px;
  position: absolute;
  top: 93px;
  left: 360px;
}
.van {
  position: absolute;
  width: 182px;
  height: 34px;
  top: 93px;
  right: 360px;
  display: flex;
  justify-content: space-between;
}
.first {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.89);
}
.navigation{
  border: none;
  background: #000;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.6);
}
.navigation_second{
  color: rgba(255, 255, 255, 0.89);
}
.underline {
  width: 24px;
  height: 4px;
  background: rgba(255, 255, 255, 0.89);
  border-radius: 20px;
}
.address{
  width: 1200px;
  height: 1008px;
  margin-top: 211px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.89);
  margin-bottom: 64px;
}
p{
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.014em;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}
.title_map{
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.89);
  margin: 64px 0;
}
.map{
  width: 1100px;
  height: 520px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.map_pic{
  width: 520px;
  height: 520px;
}
.map_text{
  width: 394px;
  height: 372px;
}
.text_item{
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  margin-bottom: 32px;
}
.text_title{
  margin-bottom: 12px;
}
.icon{
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.page_bottom {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 80px;
  margin-bottom: 14px;
}
.page_bottom_a{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}
</style>
