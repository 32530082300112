import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AddressView from '../views/AddressView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/address',
    name: 'address',
    component: AddressView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
